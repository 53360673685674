<template>
	<div id="pdf-box">
		<DefaultLoading v-if="loading || loadingBar" :loading="loading || loadingBar" />

		<Alerts v-if="showAlert" :alertType="alertType" :alertMsg="alertMsg" />
		<v-container v-if="error && !this.otpDialog">
			<v-row class="fill-height mt-5" align-content="center" justify="center">
				<v-col cols="12">
					<v-alert text color="#FF000D" border="left" class="pa-2">
						<v-icon color="#FF000D">mdi-close-circle-outline</v-icon>
						<strong>{{ errorMsg }}</strong>
					</v-alert>
				</v-col>
			</v-row>
		</v-container>

		<v-container v-if="status === 'SIGNED'">
			<v-alert class="mt-5" border="left" text type="warning">
				{{ $t("document_already_signed") }}
			</v-alert>
		</v-container>

		<v-container v-if="!docError && !loading">
			<div id="pdf-view" class="col-md-12 col-sm-12 pa-0">
				<auth-user-pdf-viewer :token="token" :authId="authId" :requestId="requestId" :fileName="fileName"
					@documentError="documentError($event)" />
			</div>
		</v-container>

		<div class="footer-message" v-if="!docError && status !== 'SIGNED' && !loading">
			<div>
				<v-row>
					<v-container class="py-0 fill-height">
						<div class="col-md-8 col-xs-12 text-left terms">
							<v-list>
								<v-list-item>
									<template v-slot:default="{ active }">
										<v-list-item-action>
											<v-checkbox @change="changeConfirm()" dark color="#F36E21"
												v-model="terms_accepted" :input-value="active"></v-checkbox>
										</v-list-item-action>

										<v-list-item-content class="white--text">
											{{ $t("read_understand_doc") }}
										</v-list-item-content>
									</template>
								</v-list-item>
							</v-list>
						</div>
						<div class="col-md-2 col-6 sign">
							<v-btn large color="#47D764" :disabled="!confirm" block dark class="sign-reject-btn"
								:loading="loading" @click="signDocument()">
								<v-icon class="mr-2">mdi-pencil-box-outline</v-icon>
								{{ $t("sign") }}
							</v-btn>
						</div>
						<div class="col-md-2 col-6 reject" v-if="!($route.query.noreject === 'true')">
							<v-btn large color="#FF000D" class="sign-reject-btn" block dark @click="reject()">
								<v-icon class="mr-2">mdi-close-circle-outline</v-icon>
								{{ $t("reject") }}
							</v-btn>
						</div>
					</v-container>
				</v-row>
			</div>
		</div>

		<sign-confirm-pad-modal :dialog="padDialog" :errorMsg="errorMsg" :error="error" @submitSignPad="submitSignPad" />

		<certificate-pin-modal :dialog="certificatePinCodeDialog" :errorMsg="errorMsg" :error="error"
			@submitCertificatePin="submitCertificatePin" />

		<reject-document-modal :dialog="rejectDocumentDialog" :errorMsg="errorMsg" :error="error" :requestId="requestId"
			:authId="authId" @rejectCertificateDocument="rejectCertificateDocument" id="rejectDocModal" />

		<single-document-summary :dialog="summaryDocumentDialog" :showAlert="showAlert" :alertType="alertType"
			:alertMsg="alertMsg" :requestId="requestId" :authId="authId" :isApproved="isApproved" />
	</div>
</template>

<script>
import SignConfirmPadModal from "../shared/SignConfirmPadModal.component";
import CertificatePinModal from "../shared/CertificatePinModal.component.vue";
import DefaultLoading from "./shared/DefaultLoading.component";
import AuthUserPdfViewer from "./shared/AuthUserPdfViewer.component";
import RejectDocumentModal from "./shared/RejectDocumentModal.component";
import SingleDocumentSummary from "./shared/SingleDocumentSummary.component";
import Alerts from "./shared/Alerts.component";
import { SignaturesService } from "../../services/signatures-service";

export default {
	components: {
		AuthUserPdfViewer,
		CertificatePinModal,
		SignConfirmPadModal,
		RejectDocumentModal,
		DefaultLoading,
		Alerts,
		SingleDocumentSummary,
	},

	data() {
		return {
			terms_accepted: null,

			loadingBar: false,
			docError: false,
			error: false,

			showPdf: false,
			otpStatus: false,
			otpDialog: false,
			otpSend: false,
			otpCode: "",
			padDialog: false,
			padStatus: false,
			padImage: "",
			userLatitude: "",
			userLongitude: "",
			errorMsg: "",
			submitLocation: false,

			status: false,

			showAlert: false,
			alertType: "",
			alertMsg: "",
			token: "",
			authId: "",
			requestId: null,
			requestType:"",
			fileName: "",
			loading: true,
			confirm: false,
			rejectDocumentDialog: false,
			signatureTransactionData: null,
			documentDetails: null,
			signaturePadImage: "",
			certificatePinCodeDialog: false,
			summaryDocumentDialog: false,
			isApproved: false
		};
	},

	mounted() {
		console.log('mounted single doc view');
		this.token = this.$route.query.token ? this.$route.query.token : localStorage.getItem("auth_user_token");
		this.requestId = Number(this.$route.params.id);

		if (this.token && this.requestId) {
			this.loadDocumentData();
		}

		localStorage.setItem("reviewed_list", "");
	},

	computed: {
		hasAssociation() {
			return this.$store.getters['common/hasAssociation']
		}
	},

	methods: {
		loadDocumentData() {
			if (this.requestId) {
				this.loading = true;

				SignaturesService.getCertificateSignatureDocument(
					this.token,
					this.requestId,
					this.$route.query.authId || null
				)
					.then((response) => {
						this.documentDetails = response.data;
						this.otpStatus = this.documentDetails.otp;
						this.requestType = this.documentDetails.type
						this.$store.commit('forms/setHasSignerForm', this.hasSignerForm);
						this.$store.commit('forms/setHasPendingSignerForm', this.hasPendingSignerForm);
						this.$store.commit('attachments/setHasAttachments', this.hasAttachments);
						this.loadPdf();

						let openSignatureOrSendOtp = localStorage.getItem('open_signature_or_send_otp');
						localStorage.removeItem('open_signature_or_send_otp');

						if (openSignatureOrSendOtp) {
							this.signDocument();
						}
					})
					.catch(() => {
						this.loading = false;
					});
			}
		},
		documentError(value) {
			this.docError = value;
			if (this.docError) {
				this.setAlert(true, "error", this.$t("document_error"));
			}
		},
		loadPdf() {
			const currentDocument = this.documentDetails;
			this.authId = this.$route.query.authId ? this.$route.query.authId : currentDocument["signerId"]["id"];
			this.fileName = currentDocument["signatureTransaction"]["description"];
			this.submitButton = this.currentDocument["status"] == "SIGNED" ? true : false;
			this.loading = false;
		},
		changeConfirm() {
			this.confirm = !this.confirm;
		},
		hideAlerts(time) {
			setTimeout(
				function () {
					this.setAlert(false, "", "");
				}.bind(this),
				time
			);
		},
		reject() {
			this.rejectDocumentDialog = true;
		},
		rejectCertificateDocument() {
			this.setAlert(true, "success", this.$t("signature_request_rejected"));
			this.summaryDocumentDialog = true;
			this.rejectDocumentDialog = false;
			this.isApproved = false;
		},
		setAlert(show, alertType, alertMsg) {
			this.showAlert = show;
			this.alertType = alertType;
			this.alertMsg = alertMsg;
		},
		signDocument() {
			this.error = false;
			console.log(this.requestType);
			if (this.documentDetails.pad) {
				this.padDialog = true;
			} else if(this.requestType == "HUMAN" && this.documentDetails.pad === false){
				this.certificatePinCodeDialog = true;
				console.log("Ha entrado en requestType");
			} else{
				this.sendOtpCode();
			}
		},
		submitSignPad(signature) {
			this.signaturePadImage = signature;
			this.padDialog = false;
			this.certificatePinCodeDialog = true;
		},
		submitCertificatePin(pinCode) {
			this.certificatePinCode = pinCode;
			this.signCertificateSignature();
		},
		async signCertificateSignature() {
			this.loading = true;
			let data = { comments: "", password: this.certificatePinCode };
			if (this.documentDetails.pad) {
				data["signatureImage"] = this.signaturePadImage;
			}

			await SignaturesService.signCertificateSignaturesDocument(
				this.token,
				data,
				this.requestId
			)
				.then((response) => {
					if (response.status == 200) {
						this.isApproved = true;
						this.summaryDocumentDialog = true;
						this.setAlert(true, "success", this.$t("doc_signed_correctly"));
					} else {
						this.setAlert(true, "error", this.$t("server_error"));
					}
					this.loading = false;
				})
				.catch((error) => {
					if (error.response.status == 422) {
						this.errorMsg =
							error.response.data.description == "SYSTEM.PKCS11_PIN_INCORRECT"
								? this.$t("document_list.invalid_pin_code")
								: this.$t("server_error");
					} else {
						this.errorMsg = this.$t("server_error");
					}
					this.error = true;
					this.loading = false;
				});
		},
		sendOtpCode() {
			this.error = false;
			if (this.requestId) {
				this.loadingBar = true;
				this.otpDialog = true;

				let data = {
					comments: "",
					authId: this.authId,
					location: "",
					lastRefresh: "",
					otp: "",
					signatureImage: "",
				};

				SignaturesService[this.token && this.authId ? `sendOtpOneTimeAuth` : `sendOtp`](this.requestId, data, this.token, this.authId).then(() => {
					this.loadingBar = false;
				})
					.catch((error) => {
						this.loadingBar = false;
						if (error.response.status == 422) {
							this.otpSend = true;
						}
					});
			} else {
				this.error = true;
			}
		},
	},
};
</script>
