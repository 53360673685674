import axios from "axios";
import { HTTP } from "@/services/http-common";
import { baseURL } from "../../config/setting";

export const SignaturesService = {
  authCheck(id, token, authId) {
    return axios.get(baseURL + `signatures/${id}/?authid=${authId}`, {
      headers: {
        Authorization: `Token ${token}`,
        Accept: "application/json",
      },
    });
  },

  getSignatureRequestDataAuth(id, citizenId, isAuthCitizenId = false, token = null) {

    let url = baseURL + `signatures/${id}/`;
    if (!isAuthCitizenId) {
      return axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      });
    } else {
      return axios.get(url + `?authid=${citizenId}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      });
    }
  },

  getSignatureRequestDocument(id, authId) {
    return HTTP.get(`signatures/${id}/document/?authid=${authId}`, {
      responseType: "arraybuffer",
      headers: { Accept: "application/pdf" },
    });
  },

  sendOtp(requestId, data) {
    return HTTP.post(`signatures/${requestId}/sign/`, data);
  },
  sendOtpOneTimeAuth(requestId, data, token, authId) {
    return axios.post(`${baseURL}signatures/${requestId}/sign/?authid=${authId}`, data, {
      headers: {
        Authorization: `Token ${token}`,
        Accept: "application/json",
      },
    });

  },

  signDocument(requestId, data) {
    return HTTP.post(`signatures/${requestId}/sign/`, data);
  },

  rejectDocument(requestId, data) {
    return HTTP.post(`signatures/${requestId}/reject/`, data);
  },

  getSignatureRequestData(id, authId = null) {
    return HTTP.get(`signatures/${id}/?authid=${authId}`);
  },

  login(username, password) {
    const token = `${username}:${password}`;
    const encodedToken = Buffer.from(token, "utf8").toString("base64");
    return axios.get(baseURL + `users/${username}/`, {
      headers: {
        Authorization: `Basic ${encodedToken}`,
        Accept: "application/json",
      },
    });
  },

  signCertificateSignaturesDocument(apiToken, data, signatureId) {
    return axios.post(baseURL + `signatures/${signatureId}/sign/`, data, {
      headers: {
        Authorization: `Token ${apiToken}`,
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
  },


  getCertificateSignatureRequests(token, params, authId) {
    let url = baseURL + 'signatures/';
    if (authId !== null) {
      url += `?authid=${authId}`;
    }

    return axios.get(url, {
      params: params,
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  },

  getPendingCertificateSignatureRequests(token, params, authId) {
    let url = baseURL + 'signatures/';
    if (authId !== null) {
      url += `?authid=${authId}`;
    }

    const paramss = { ...params, status: 20 };
    return axios.get(url, {
      params: paramss,
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  },


  getCompletedCertificateSignatureRequestList(token, params, authId) {
    const url = baseURL + 'signatures/';
    const headers = {
      Authorization: `Token ${token}`,
    };

    // Clone params to avoid mutating the original object
    const params30 = { ...params, status: 30 };
    const params40 = { ...params, status: 40 };

    if (authId !== null) {
      params30.auth_id = authId;
      params40.auth_id = authId;
    }

    const request30 = axios.get(url, { params: params30, headers: headers });
    const request40 = axios.get(url, { params: params40, headers: headers });

    return axios.all([request30, request40])
      .then(axios.spread((response30, response40) => {
        const combinedData = [
          ...response30.data.signatureRequestsList,
          ...response40.data.signatureRequestsList,
        ];
        return {
          ...response30,  // Use the response structure of response30
          data: {
            ...response30.data,
            signatureRequestsList: combinedData,
          },
        };
      }));
  },



  getCertificateSignatureRequestDocument(requestId, token, authId) {
    let url = baseURL + `signatures/${requestId}/document/`;
    if (authId !== null) {
      url += `?authid=${authId}`;
    }
    return axios.get(
      url,
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/pdf",
        },
      }
    );
  },

  rejectCertificateDocument(apiToken, data, signatureId) {
    return axios.post(baseURL + `signatures/${signatureId}/reject/`, data, {
      headers: {
        Authorization: `Token ${apiToken}`,
        Accept: "application/json",
      },
    });
  },

  getCertificateSignatureDocument(apiToken, signatureId, authId = null) {
    let url = `${baseURL}signatures/${signatureId}/sign/`;
    if (authId) {
      url = url + `?authId=${authId}`;
    }

    return axios.get(url, {
      headers: {
        Authorization: `Token ${apiToken}`,
        Accept: "application/json",
      },
    });
  },
  getBatchCertificateSignatureRequests(token) {
    return axios.get(`${baseURL}signatures/?page=1&status=20`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  },

  downloadEvidenceReport(id) {
    return HTTP.get(`evr/${id}/`, {
      responseType: "arraybuffer",
      headers: { Accept: "application/pdf" },
    });
  },
};
